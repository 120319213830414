.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Nav{
  background-color: lightblue;
  display: flex;
  justify-content: space-around;
  font-size: large;
  padding: 5px;
}

#journalEntries{
  display:grid;
  /* justify-content:space-evenly ; */
  /* border: 1px solid black; */
}
#container {
  border: 1px solid black;
}

h1{
  text-align:center;
  color: blue;
}

.App{
  background-color: lightgray;
  box-sizing: border-box;
  height: fit-content;
  /* height: 100% */
  /* opacity: 50%; */
  
}

#Home, #About, #Edit{
  height: 100vh;
  background-color: lightgray;
}

#Edit Form, #Home Form, #Journals Form {
  margin: 20px;
  font-size: 30px;
}

#JournalForm{
  box-sizing: border-box;
}

#date{
  text-decoration: underline;
  font-size:10px ;
}

#title{
  margin-top: -10px;
}

#AllNavLinks{
  color: black;
  font-size: 25px;
}